import React from "react";
import { LazyLoadImages } from "@quintype/components";
import { SectionPage as SectionPageBase } from "../components/pages/section";
import { TagPage as TagPageBase } from "../components/pages/tag";
import { AuthorPage as AuthorPageBase } from "../components/pages/author";
import { SearchPage as SearchPageBase } from "../components/pages/search";
import { CatalogPage as CatalogPageBase } from "../components/pages/catalog";
import { BundlePage as BundlePageBase } from "../components/pages/bundle";
import { IplPage as IplPageBase } from "../components/pages/ipl";
import { WebstoriesPage as WebstoriesPageBase } from "../components/pages/webstories-page";
import { VikatanSeriesPage as VikatanSeriesPageBase } from "../components/pages/vikatan-series-page";
import { ViBundlePage as ViBundlePageBase } from "../components/pages/collection-webstories";
import { SeriesLandingPage as SeriesLandingPageBase } from "../components/pages/vikatan-series-bundle";
import { VideoPage as VideoPageBase } from "../components/pages/video-page";
import { TaboolaAd } from "../components/atoms/taboola-ad";
import { VikatanSectionHeader } from "../components/rows/ad-free";
import { StorePage as StorePageBase } from "../components/pages/store-page";
import { WorldcupPage as WorldcupPageBase } from "../components/pages/cricket-worldcup";
import { WCDetailPage as WCDetailBase } from "../components/pages/worldcup-detail";
import { STIPLPage as STIPLPageBase } from "../components/pages/cricket-ipl";
import { T20WorldcupPage as T20WorldcupPageBase } from "../components/pages/cricket-t20-worldcup";
import { ChampionsTrophy2025 as ChampionsTrophy2025Base } from "../components/pages/champions-trpohy";
import { OlympicsPage as OlympicsPageBase } from "../components/pages/olympics";
import { AudioMagazinePage as AudioMagazinePageBase } from "../components/pages/audio-magazine";
import { AudioMagazineStoryPage as AudioMagazineCollectionPageBase } from "../components/pages/audio-magazine-collection";
import { AudioMagazineCollectionListPage as AudioMagazineCollectionListPageBase } from "../components/pages/audio-magazine-collection-list";
import { NTPage as NTPageBase } from "../components/pages/nt-page";
import { VikatanLoginPage as VikatanLoginBase } from "../components/pages/vikatan-login";
import { AudioMagazineEpisode as AudioMagazineEpisodePageBase } from "../components/pages/audio-magazine-episode";
import { AudioMagazineSearchPage as AudioMagazineSearchPageBase } from "../components/pages/audio-magazine-search";
import { IPL2025Page as IPL2025PageBase } from "../components/pages/cricket-ipl-2025";
import { VikatanPlusPage as VikatanPlusPageBase } from "../components/pages/vikatan-plus";

function wrapLazyLoadImages(Template, adShow = true) {
  const ImageLazyLoadedComponent = props => {
    return (
      <React.Fragment>
        {adShow && <VikatanSectionHeader />}

        <LazyLoadImages margin={"150px"}>
          <Template {...props} />
        </LazyLoadImages>
        {adShow && (
          <div className="taboola-outer container">
            <TaboolaAd
              config={{
                mode: "thumbnails-a",
                id: "taboola-below-category-thumbnails",
                placement: "Below Category Thumbnails"
              }}
              url={global && global.location && global.location.href}
              className={"taboola-article"}
              directory={"category"}
            />
          </div>
        )}
      </React.Fragment>
    );
  };
  ImageLazyLoadedComponent.displayName = `WrapLazyLoadImages(${Template.name})`;
  return ImageLazyLoadedComponent;
}

export const SectionPage = wrapLazyLoadImages(SectionPageBase);
export const TagPage = wrapLazyLoadImages(TagPageBase);
export const AuthorPage = wrapLazyLoadImages(AuthorPageBase);
export const SearchPage = wrapLazyLoadImages(SearchPageBase);
export const CatalogPage = wrapLazyLoadImages(CatalogPageBase);
export const BundlePage = wrapLazyLoadImages(BundlePageBase);
export const IplPage = wrapLazyLoadImages(IplPageBase);
export const WebstoriesPage = wrapLazyLoadImages(WebstoriesPageBase);
export const VikatanSeriesPage = wrapLazyLoadImages(VikatanSeriesPageBase);
export const ViBundlePage = wrapLazyLoadImages(ViBundlePageBase);
export const SeriesLandingPage = wrapLazyLoadImages(SeriesLandingPageBase);
export const VideoPage = wrapLazyLoadImages(VideoPageBase);
export const StorePage = wrapLazyLoadImages(StorePageBase);
export const WorldcupPage = wrapLazyLoadImages(WorldcupPageBase, false);
export const WCDetailPage = wrapLazyLoadImages(WCDetailBase, false);
export const STIPLPage = wrapLazyLoadImages(STIPLPageBase, false);
export const T20WorldcupPage = wrapLazyLoadImages(T20WorldcupPageBase, false);
export const ChampionsTrophy2025 = wrapLazyLoadImages(ChampionsTrophy2025Base, false);
export const OlympicsPage = wrapLazyLoadImages(OlympicsPageBase, false);
export const AudioMagazinePage = wrapLazyLoadImages(AudioMagazinePageBase, false);
export const AudioMagazineCollectionPage = wrapLazyLoadImages(AudioMagazineCollectionPageBase, false);
export const AudioMagazineCollectionListPage = wrapLazyLoadImages(AudioMagazineCollectionListPageBase, false);
export const NTPage = wrapLazyLoadImages(NTPageBase, false);
export const VikatanLoginPage = wrapLazyLoadImages(VikatanLoginBase, false);
export const AudioMagazineEpisodePage = wrapLazyLoadImages(AudioMagazineEpisodePageBase, false);
export const AudioMagazineSearchPage = wrapLazyLoadImages(AudioMagazineSearchPageBase, false);
export const IPL2025Page = wrapLazyLoadImages(IPL2025PageBase, false);
export const VikatanPlusPage = wrapLazyLoadImages(VikatanPlusPageBase, false);
