import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BigStoryCardCurve from "../big-story-card-curve/index";
import SmallStoryCardCurve from "../small-story-card-curve/index";

import styles from "./styles.m.css";

const TenStoryTwoCollectionStoryStack = ({
  mobileStoryCount,
  stories,
  className,
  verticalimg = false,
  circleimage = true,
  bgColorClass = ""
}) => {
  if (stories.length < 1) {
    return null;
  }
  const [pageName, setPageName] = useState("home");
  useEffect(() => {
    const getpageName = global && global.location.pathname;
    const pageName = getpageName.split("/").pop();
    const getPFrom = pageName || "home";
    setPageName(getPFrom);
  }, []);

  const stack = stories.map((story, index) => (
    <SmallStoryCardCurve
      story={story}
      verticalimg={verticalimg}
      className={`${styles["story-card"]}`}
      key={story.id}
      pFrom={`${pageName}-twelve-story-collection`}
      circleimage={circleimage}
      bgColorClass={bgColorClass}
    />
  ));
  return <div className={`${styles["base"]} ${className}`}>{stack}</div>;
};

TenStoryTwoCollectionStoryStack.propTypes = {
  bigStory: PropTypes.bool,
  mobileStoryCount: PropTypes.number,
  bigimage: PropTypes.bool,
  verticalimg: PropTypes.bool,
  stories: PropTypes.arrayOf(BigStoryCardCurve.propTypes.story),
  className: PropTypes.string,
  bigStoryHover: PropTypes.bool,
  circleimage: PropTypes.bool,
  bgColorClass: PropTypes.string
};

export default TenStoryTwoCollectionStoryStack;
