/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { DeepBI } from "../../integrations";
import styles from "./audio-magazine.m.css";
import { get } from "lodash";
import Icon from "../atoms/icon";
import ResponsiveImageWithFallback from "../atoms/responsive-image-with-fallback";
import { generateImageSources } from "../utils/utils";
import { connect } from "react-redux";
import assetify from "@quintype/framework/assetify";
import loader from "../../../assets/images/loader.gif";
import { LoadmoreAudiostories } from "../../api";
import { Link, WithHostUrl } from "@quintype/components";
import MobileLogin from "../rows/header/user-login/login-mobile";
class AudioMagazineCollection extends React.Component {
  constructor() {
    super();
    this.state = {
      playing: false,
      currentStoryId: null,
      playingAll: false,
      visibleItems: [], // Stories that are currently visible
      offset: 0, // Start with an initial offset of 0 (will update with preloaded stories)
      limit: 10, // Number of stories to fetch per API request
      loading: false, // Track the loading state
      hasMoreStories: false, // Whether there are more stories to load
      collectionName: "",
      userStatus: true,
      showLoginModal: false
    };
  }
  componentDidMount() {
    const { data } = this.props;

    global.userPromise.catch(() => ({})).then(user => {
      user && this.setState({ userType: user.userType, userStatus: user.loggedOut });
    });

    const initialStories = get(data, ["collection", "items"], []);
    const totalStoryCount = get(data, ["collection", "total-count"], 0);
    const collectionName = get(data, ["collection", "slug"], "");

    global.userPromise.catch(() => ({})).then(user => {
      if (user) {
        this.setState({ userStatus: user.loggedOut }, () => {
          this.checkForAudioBook(initialStories);
        });
      }
    });

    // Initialize visibleItems with preloaded stories and set initial offset based on the number of preloaded stories
    this.setState({
      visibleItems: initialStories,
      totalStoryCount: totalStoryCount,
      offset: initialStories.length, // Offset is the number of initially loaded stories
      hasMoreStories: initialStories.length < totalStoryCount,
      collectionName: collectionName
      // Check if there are more stories to load
    });
  }

  checkForAudioBook = initialStories => {
    // Get the pathname from the URL
    const pathname = window && window.location && window.location.pathname;
    const slugSegments = pathname.split("/").filter(Boolean); // Split and filter out empty strings
    const lastSegment = slugSegments[slugSegments.length - 1]; // Get the last part of the path

    // Check if the last segment is a valid story slug
    // Assuming a story slug is alphanumeric and not purely numeric
    const isStorySlug = isNaN(Number(lastSegment)); // Check if it's not a number

    if (isStorySlug) {
      // Find the matching story in the initialStories collection
      const matchingStory = initialStories.find(item => {
        const slug = get(item, ["story", "slug"], ""); // Full slug from the story
        const storySlugSegments = slug.split("/"); // Split the story's slug
        const storyLastSegment = storySlugSegments[storySlugSegments.length - 1]; // Get the last part of the story's slug

        // Compare the last segment of the URL with the story's slug
        return lastSegment === storyLastSegment;
      });

      if (matchingStory) {
        const story = get(matchingStory, ["story"], null); // Extract the story object
        if (story) {
          this.handlePlay(story); // Handle the play action for the matching story
        }
      }
    }
  };

  componentDidUpdate(prevProps) {
    const { currentPlayingStory, PlayAudio } = this.props;

    // Check if PlayAudio has changed or if the current story has changed
    if (
      PlayAudio !== prevProps.PlayAudio ||
      (currentPlayingStory && currentPlayingStory.id !== this.state.currentStoryId)
    ) {
      if (currentPlayingStory) {
        // Update the state to reflect the new story
        this.setState({
          playing: PlayAudio, // Or true/false based on PlayAudio
          currentStoryId: currentPlayingStory.id
        });
      } else {
        // Handle case where there is no currentPlayingStory
        this.setState({
          playing: false,
          currentStoryId: null
        });
      }
    }
  }

  // Function to fetch stories from the API
  fetchStories = async offset => {
    const { data } = this.props;

    const collectionslug = get(data, ["collection", "slug"], "");

    const { limit, visibleItems, collectionName } = this.state;
    const collectionId = collectionName || collectionslug; // Replace with your actual collectionId

    this.setState({ loading: true });

    LoadmoreAudiostories(collectionId, limit, offset)
      .then(res => {
        // Assuming the API response contains stories
        const newStories = res.items || [];

        this.setState(prevState => ({
          visibleItems: [...prevState.visibleItems, ...newStories], // Append new stories to existing ones
          offset: prevState.offset + limit, // Update offset for the next fetch
          loading: false, // Set loading to false
          hasMoreStories: visibleItems.length + newStories.length < prevState.totalStoryCount // Check if there are more stories to load
        }));
      })
      .catch(error => {
        console.error(error);
        this.setState({ loading: false }); // Set loading to false on error
      });
  };

  // Handle loading more stories on button click
  handleLoadMore = () => {
    const { offset, hasMoreStories } = this.state;

    if (hasMoreStories) {
      this.fetchStories(offset); // Fetch the next set of stories using the updated offset
    }
  };

  handlePlay = story => {
    if (this.state.userStatus) {
      this.setState({ showLoginModal: true });
      DeepBI.PingLoginevents("Login_widget_view", "", "", "", "", "vikatan_play_player");
      return;
    }

    const storyId = get(story, ["id"]);
    this.props.setCurrentStory(story);
    this.props.setCurrentCollection(this.props.data.collection);
    this.props.setMiniPlayerVisibility(true); // Dispatching action to Redux
    this.props.setAudioplaying(true);
    this.setState({ playing: true, currentStoryId: storyId });

    if (this.props.ClosePlayer) {
      this.props.setClosePlayer(false);
    }
  };

  handlePause = story => {
    this.props.setAudioplaying(false);
    this.setState({ playing: false, currentStoryId: null });
    // props.setCurrentStory(story);
  };

  handleMobileLoginClose = () => {
    this.setState({ showLoginModal: false });
  };

  formatTime = time => {
    if (isNaN(time)) {
      return "00:00";
    }
    const remainder = time % 3600;
    const minutes = Math.floor(remainder / 60);
    const seconds = Math.floor(remainder % 60);

    const mm = minutes.toString().padStart(2, "0");
    const ss = seconds.toString().padStart(2, "0");

    return `${mm}:${ss}`;
  };

  audiomagazineCollectionPath = slug => {
    return `/vikatan-play/${slug}`;
  };

  render() {
    const { data } = this.props;
    // const items = get(data, ["collection", "items"], []);
    const collectionName = get(data, ["collection", "name"]);
    const collectionSlug = get(data, ["collection", "id"], "");
    const LinkSlug = get(data, ["collection", "slug"], "");
    const collectionImage = get(data, ["collection", "metadata", "cover-image", "cover-image-s3-key"], "");
    const collectionImageMetaData = get(data, ["collection", "metadata", "cover-image", "cover-image-metadata"], {});
    const totalStoryCount = get(data, ["collection", "total-count"]);
    // const hasCollectionType = items.some(item => get(item, "type") === "collection");
    const Heading = get(data, ["collection", "metadata", "english-title"]) || get(data, ["collection", "name"]);

    const { visibleItems, loading, hasMoreStories } = this.state;

    // if (hasCollectionType) {
    //   return null;
    // }

    return (
      <React.Fragment>
        <div className={`js-story-main-container ${`${styles["base"]}`} ${`${styles["magazine-story-page"]}`}`}>
          <div className="container">
            <ul className={styles["collection-breadcrums"]} itemScope itemType="http://schema.org/BreadcrumbList">
              <li
                className={styles.breadcrumb}
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
              >
                <WithHostUrl>
                  {({ primaryHostUrl }) => (
                    <Link
                      aria-label="Vikatan"
                      href={primaryHostUrl ? primaryHostUrl + "/vikatan-play" + "/" : "/"}
                      itemprop="item"
                    >
                      <span itemProp="name">Home</span>
                    </Link>
                  )}
                </WithHostUrl>
                <span>/</span>
                <meta itemProp="position" content={1} />
              </li>
              {collectionName && (
                <li
                  className={styles.breadcrumb}
                  itemProp="itemListElement"
                  itemScope
                  itemType="http://schema.org/ListItem"
                >
                  <Link
                    aria-label={`${"Navigate to "} ${this.audiomagazineCollectionPath(LinkSlug)}`}
                    href={this.audiomagazineCollectionPath(LinkSlug)}
                    itemprop="item"
                  >
                    <span itemProp="name">{collectionName}</span>
                  </Link>
                  <meta itemProp="position" content={2} />
                </li>
              )}
            </ul>
          </div>
          {this.state.showLoginModal && <MobileLogin onClose={this.handleMobileLoginClose} />}

          {visibleItems && visibleItems.length > 0 ? (
            <div className={`${styles["episode-page"]} container`}>
              <div className={`${styles["collection-page-grid"]}`}>
                <div className={`${styles["top-collection"]} ${styles["collection-section"]}`}>
                  {/* {collectionImage && ( */}
                  <div className={styles["cover-image"]}>
                    <ResponsiveImageWithFallback
                      className={styles["image-wrapper"]}
                      slug={collectionImage}
                      metadata={collectionImageMetaData}
                      alt={collectionName}
                      imgParams={{ auto: ["format", "compress"] }}
                      sources={generateImageSources(
                        { aspectRatio: [4, 3], screenWidthCoverage: 0.75 },
                        { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
                      )}
                    />
                  </div>
                  {/* )} */}
                  <div>
                    {/* <h4>{collectionName}</h4> */}
                    {Heading && <h1 className={styles["collection-heading"]}>{Heading}</h1>}
                    <h2 className={`${styles["collection-title"]}`}>{collectionName}</h2>
                    <h2 className={styles["free-trail-title"]}>Get 30 Days Free Trial</h2>
                    <p className={styles["free-trail-desc"]}>
                      விகடன் App-ஐ இன்ஸ்டால் செய்து இப்போதே 30 நாள்களுக்கான இலவச Trial-ஐப் பெற்றிடுங்கள். அணிலாடும்
                      முன்றில், வேட்டை நாய்கள், நீரதிகாரம் என சூப்பர் ஹிட் ஆடியோ புத்தகங்களை Ad free-யாக Enjoy
                      செய்திடுங்கள்!
                    </p>

                    <div>
                      <Link href="https://vikatanmobile.page.link/home-page">
                        <div className={styles["install-appbar"]}>
                          <Icon type="icon-apple" className={styles["apple-icon"]} />
                          <Icon type="icon-google-play" />
                          Install app & Get Free Trial
                        </div>
                      </Link>
                    </div>

                    <div className={styles["collection-audio-bar"]}>
                      <div />
                    </div>
                  </div>
                </div>
                <div className={`${styles["all-episodes"]}`}>
                  <div className={styles["all-episodes-title"]}>
                    <span className={styles["all-episodes-title-content"]}>All Episodes ({totalStoryCount})</span>
                  </div>
                  <div className={styles["all-episodes-items"]}>
                    {visibleItems.map((item, index) => {
                      const headline = get(item, ["story", "headline"], "");
                      const heroImageKey = get(item, ["story", "hero-image-s3-key"], "");
                      // const heroImageUrl = `https://media.vikatan.com/${heroImageKey}`;
                      const story = get(item, ["story"]);
                      const storyId = get(item, ["story", "id"]);
                      const isCurrentStory = this.state.currentStoryId === storyId;
                      const slug = get(item, ["story", "slug"]);
                      //  const formattedSlug = slug && slug.split("/").pop(); // Extract the last part of the slug
                      const duration = get(item, ["story", "metadata", "story-attributes", "audiolength"], 0);

                      return (
                        <div key={index}>
                          <Link
                            href={`/vikatan-play/${collectionSlug}/${slug}`}
                            className={styles["item-container"]}
                            callback={() => {
                              this.handlePlay(story);
                            }}
                          >
                            <div>
                              <ResponsiveImageWithFallback
                                className={styles["image-wrapper"]}
                                slug={heroImageKey}
                                metadata={headline}
                                alt={headline}
                                imgParams={{ auto: ["format", "compress"] }}
                                sources={generateImageSources(
                                  { aspectRatio: [4, 3], screenWidthCoverage: 0.75 },
                                  { aspectRatio: [4, 3], screenWidthCoverage: 0.3 }
                                )}
                              />
                            </div>
                            <div className={styles["audio-story-content"]}>
                              <h5>{headline}</h5>
                              <div className={styles["audio-story-timer"]}>
                                <div className={styles["timer"]}>
                                  <Icon type="icon-timer" /> <span>{this.formatTime(duration)}</span>
                                </div>
                                <div className={styles["audio-controls-top-section"]}>
                                  <div className={`${styles["audio-control-icons"]}`}>
                                    {isCurrentStory && this.state.playing ? (
                                      <Icon type="pause" className={`${styles["pause"]} ${styles["audio-icon"]}`} />
                                    ) : (
                                      <Icon type="play" className={`${styles["play"]} ${styles["audio-icon"]}`} />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                    {/* Load More Button */}
                    {hasMoreStories && (
                      <button onClick={this.handleLoadMore} className={styles["load-more-button"]} disabled={loading}>
                        {loading ? "Loading..." : "Load More"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles["loader-wrapper"]}>
              <img alt="Vikatan" src={assetify(loader)} className={styles["loader"]} />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

AudioMagazineCollection.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    magazine: PropTypes.array
  }),
  pageType: PropTypes.string,
  config: PropTypes.shape({
    disableAds: PropTypes.bool,
    contentInsightsId: PropTypes.string
  })
};

function mapDispatchToProps(dispatch) {
  return {
    setMiniPlayerVisibility: isVisible => {
      dispatch({
        type: "SET_MINI_PLAYER_VISIBILITY",
        payload: isVisible
      });
    },
    setAudioplaying: isVisible => {
      dispatch({
        type: "AUDIO_PLAYING",
        payload: isVisible
      });
    },
    setCurrentStory: story => {
      dispatch({
        type: "CURRENT_STORY",
        payload: story
      });
    },
    setCurrentCollection: collection => {
      dispatch({
        type: "CURRENT_COLLECTION",
        payload: collection
      });
    },
    setClosePlayer: isVisible => {
      dispatch({
        type: "CLOSE_PLAYER",
        payload: isVisible
      });
    }
  };
}

function mapStateToProps(state) {
  return {
    data: get(state, ["qt", "data"]),
    enablePlayer: get(state, ["ShowPlayer"]),
    PlayAudio: get(state, ["PlayAudio"]),
    ClosePlayer: get(state, ["ClosePlayer"]),
    currentPlayingStory: get(state, ["CurrentStory"])
  };
}

const AudioMagazineCollectionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioMagazineCollection);

export { AudioMagazineCollectionPage as AudioMagazineStoryPage };
