import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import styles from "./webstories.m.css";
import { addWithoutAdsToTemplates } from "../get-collection-template";
import { LazyCollection } from "@quintype/components";
import { GA, WebEngage, DeepBI, PGAD } from "../../integrations";
import Icon from "../atoms/icon";
import { pdfIssueDateFormat } from "../utils/utils";
import { fetchCollection } from "../../api";

const newTemplates = addWithoutAdsToTemplates("home");
function getAdsWrappedCollectionTemplate(designTemplate) {
  return newTemplates[designTemplate] || newTemplates.defaultTemplate;
}

export class VikatanPlusPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCollectionIndex: 0,
      selectedMenuItem: "home",
      collection: get(this.props.data, ["collection"], {}),
      loading: false
    };
    this.lazyCollectionRefs = [];
  }

  componentDidMount() {
    GA.registerPageView(this.props.pageType, {});
    WebEngage.registerPageView();
    DeepBI.registerPageView({ collection: this.props });
    PGAD.loadScript();
  }

  handleMenuClick = slug => {
    console.log("Selected Menu Item Index:", slug);
    this.setState({ loading: true });
    let collection = get(this.state, ["collection"], {});

    fetchCollection(slug, 0, 5, {}).then(response => {
      const newCollection = {
        ...collection,
        items: collection.items.map(
          (item, i) =>
            i === 0
              ? {
                  ...item,
                  items: response.items // ← replace the entire array
                }
              : item
        )
      };
      this.setState({ collection: newCollection, selectedMenuItem: slug || "home", loading: false });
    });
  };

  render() {
    let collection = get(this.state, ["collection"], {});
    const VikatanPlusMagazineCollection = get(this.props.data, ["magazine"], []).filter(
      magazine => magazine.id === 84249
    );

    const plusheader = get(this.props.data, ["header"], "");
    const plusheaderspecial = get(plusheader, "VikatanplusSpecialHeaderMenu", []);

    const plusheaderspecialitems = get(plusheaderspecial, "items", []);

    return (
      <div className={styles["vikatan-plus-page"]}>
        <div className={`${styles["plus-page-header"]} container`}>
          <div className={styles["vikatan-plus-logo"]}>
            <Icon type="v-plus-logo" />
          </div>
          <div className={`${styles["menu-list-wrapper"]} container`}>
            <ul className={`${styles["menu-list"]} container`}>
              <li
                className={`${styles["menu-item"]} ${this.state.selectedMenuItem === "home" ? styles["active"] : ""}`}
                onClick={() => this.setState({ selectedMenuItem: "home" })}
              >
                <Icon type="icon-home-plus" className={styles["home-icon"]} />
              </li>
              {plusheaderspecialitems.length > 0 &&
                plusheaderspecialitems.map((item, index) => {
                  const url = get(item, ["path"], "");
                  const parts = url.split("/");
                  const finalPart = parts[parts.length - 1];
                  return (
                    <li
                      key={index}
                      className={`${styles["menu-item"]} ${
                        this.state.selectedMenuItem === finalPart ? styles["active"] : ""
                      }`}
                      onClick={() => this.handleMenuClick(finalPart)}
                    >
                      {item.title}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>

        {this.state.loading && <div className={styles["loader"]}>Loading...</div>}

        <LazyCollection
          collection={collection}
          magazine={this.props.data.magazine}
          config={this.props.config}
          collectionTemplates={getAdsWrappedCollectionTemplate}
          lazyAfter={5}
          accentColor={"#2f81cd"}
        />

        <div className={`${styles["magazine-wrapper"]} container`}>
          <div className={styles["vikatan-plus-magazines"]}>
            <h3 className={styles["vikatan-plus-title"]}>விகடன் ப்ளஸ் - லேட்டஸ்ட் இதழ்கள்</h3>
            <div className={styles["magazine-list"]}>
              <div className={styles["magazine-item-wrapper"]}>
                {VikatanPlusMagazineCollection.map(magazine =>
                  magazine.collections.map(collection => {
                    const issueDate = get(collection, ["collection-date"]);
                    const formattedIssueDate = `${pdfIssueDateFormat(issueDate, "custom")}`;
                    return (
                      <div key={collection.id} className={styles["magazine-item"]}>
                        <a href={`vikatan-plus/${formattedIssueDate}`} className={styles["magazine-link"]}>
                          <img
                            src={get(collection, ["metadata", "cover-image", "url"], "")}
                            alt={collection.name}
                            className={styles["magazine-cover"]}
                          />
                        </a>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VikatanPlusPage.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    magazine: PropTypes.array
  }),
  pageType: PropTypes.string,
  config: PropTypes.shape({
    disableAds: PropTypes.bool,
    contentInsightsId: PropTypes.string
  })
};
