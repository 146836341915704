import React from "react";
import styles from "./styles.m.css";
import { DeepBI } from "../../../integrations";

const FloatingWidget = () => {
  const handleClickEvents = () => {
    DeepBI.PingIplQuiz("iplquizwidgetClick");
  };
  return (
    <div className={styles["floating-ipl"]} onClick={handleClickEvents}>
      <a href="https://sports.vikatan.com/cricket/ipl-2025" target="_blank" rel="noopener noreferrer">
        <img src="https://media.vikatan.com/vikatan/2025-04-01/000k9j4w/IPLfloatingbanner.gif?format=webp&w=150&q=90" />
      </a>
    </div>
  );
};

export default FloatingWidget;
